
import { defineComponent } from 'vue';
import {
    IonPage,
    IonIcon,
} from '@ionic/vue';

export default defineComponent({
    name: 'SignUpGuestForm',

    components: {
        IonPage,
        IonIcon,
    },
    data: ()=>({
    }),
    methods: {
        goTo(target: string) {
            this.$router.push(target);
        }
    }
});
